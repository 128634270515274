"use client";

import { useTheme } from "next-themes";
import { Button } from "../elements/Button";
import { Sun, Moon } from "@phosphor-icons/react";


export default function ThemeChanger() {
  const { theme, setTheme } = useTheme();

  const handleThemeChange = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  return (
    <Button variant="menu" size="icon" onClick={handleThemeChange}>
      {theme === "light" ? (
        <Sun weight="duotone" className="text-lg" />
      ) : (
        <Moon weight="duotone" className="text-lg" />
      )}
    </Button>
  );
}
